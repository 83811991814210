
import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import Route from "./routes";
import '../css/style.css'
import '../css/mediaquery.css'
import NewUserForm from "../pages/NewUserForm";
const NewSignup = lazy(() => import("../pages/NewSignup"));
const SignupForm = lazy(() => import("../pages/SignupForm"));
const SchoolEvent = lazy(() => import("../pages/TeacherSchoolEvent"));
const OtpVerify = lazy(() => import("../pages/OtpVerify"));
const MobilePage = lazy(() => import('../pages/MobilePage' /* webpackChunkName: "MobilePage" */));
const Login = lazy(() => import('../pages/Login' /* webpackChunkName: "Login" */));
const Home = lazy(() => import('../pages/Home' /* webpackChunkName: "Home" */));
const Progress = lazy(() => import('../pages/Progress' /* webpackChunkName: "Progess" */));
const Song = lazy(() => import('../pages/Song' /* webpackChunkName: "Song" */));
const Courses = lazy(() => import('../components/Teacher/Courses' /* webpackChunkName: "Song" */));
const StudentCourse = lazy(() => import('../components/Teacher/StudentCourse'));
const StudentCourseHistory = lazy(() => import('../components/Teacher/StudentCourseHistory'));
const TeachingTips = lazy(() => import('../pages/TeachingTips' /* webpackChunkName: "TeachingTips" */));
// const Schedule = lazy(() => import('../pages/Schedule' /* webpackChunkName: "Schedule" */));
const ImportData = lazy(() => import('../pages/ImportData' /* webpackChunkName: "ImportData" */));
const Lesson = lazy(() => import('../pages/Lesson' /* webpackChunkName: "Lesson" */));
const ObjectiveDetails = lazy(() => import('../pages/ObjectiveDetails'));
const NewObjectiveVideo = lazy(() => import("../pages/NewObjectiveVideo" /* webpackChunkName: "NewObjectiveVideo" */));
const TeacherList = lazy(() => import('../components/Teacher/TeacherList' /* webpackChunkName: "TeacherList" */));
const ClusterList = lazy(() => import('../components/Cluster/ClusterList' /* webpackChunkName: "ClusterList" */));
const Exercises = lazy(() => import('../pages/Exercises' /* webpackChunkName: "ClusterList" */));
const VideoPiece = lazy(() => import('../components/Assessment/VideoPiece' /* webpackChunkName: "VideoPiece" */));
const StudentAttendance = lazy(() => import('../pages/StudentAttendance' /* webpackChunkName: "VideoPiece" */));
const FlipBooks = lazy(() => import('../pages/FlipBooks' /* webpackChunkName: "FlipBooks" */));
const SpeechdramaInstrument = lazy(() => import("../components/Teacher/SpeechdramaInstruments" /* webpackChunkName: "SpeechdramaInstrument" */));
const HindustaniVocalsInstruments = lazy(() => import("../components/Teacher/HindustaniVocalsInstruments" /* webpackChunkName: "HindustaniVocalsInstruments" */));

const DrumsInstrument = lazy(() => import("../components/Teacher/DrumsInstrument" /* webpackChunkName: "DrumsInstrument" */));
const DrumsFlipBook = lazy(() => import("../pages/DrumsFlipBook" /* webpackChunkName: "DrumsFlipBook" */));
const SpeechdramaFlipBook = lazy(() => import("../pages/SpeechdramaFlipBook" /* webpackChunkName: "SpeechdramaFlipBook" */));
const HindustaniVocalFlipBook = lazy(() => import("../pages/HindustaniVocalFlipBook" /* webpackChunkName: "SpeechdramaFlipBook" */));
const MusicTheroy = lazy(() => import('../components/Teacher/MusicTheroy' /* webpackChunkName: "MusicTheroy" */));
const AssessmentsOld = lazy(() => import('../pages/AssessmentsOld' /* webpackChunkName: "AssessmentsOld" */));
const Assessments = lazy(() => import('../pages/Assessments' /* webpackChunkName: "Assessments" */));
const StudentDetails = lazy(() => import('../pages/StudentSummary' /* webpackChunkName: "StudentDetails" */));
const ClassRoom = lazy(() => import('../pages/ClassRoom' /* webpackChunkName: "classRooms" */));
const Assignment = lazy(() => import('../pages/Assignment' /* webpackChunkName: "Assignment" */));
const Signup = lazy(() => import('../pages/Signup' /* webpackChunkName: "signUp" */));
const ForgetPassword = lazy(() => import('../components/ForgotPassword' /* webpackChunkName: "ForgetPassword" */));
const ConfirmPassword = lazy(() => import('../components/ConfirmPassword' /* webpackChunkName: "ConfirmPassword" */));
const ClusterExam = lazy(() => import('../components/Exam/Cluster/ClusterExam' /* webpackChunkName: "ClusterExam" */));
const TeacherExam = lazy(() => import('../components/Exam/Teacher/TeacherExam' /* webpackChunkName: "TeacherExam" */));
const StudentExam = lazy(() => import('../components/Exam/Student/StudentExam' /* webpackChunkName: "StudentExam" */));
const StartExam = lazy(() => import('../components/Exam/Student/StartExam' /* webpackChunkName: "StartExam" */));
const StudentView = lazy(() => import("../components/Exam/Teacher/StudentView" /* webpackChunkName: "StudentList" */));
const ExamList = lazy(() => import("../components/Exam/Cluster/ExamList" /* webpackChunkName: "StudentList" */));
const NewSchedule = lazy(() => import("../pages/NewSchedule" /* webpackChunkName: "StudentList" */));
const StudentSummary = lazy(() => import("../components/Schedule/StudentSummary" /* webpackChunkName: "StudentSummary" */));
const PracticalExamList = lazy(() => import("../components/Exam/Student/PracticalExamList" /* webpackChunkName: "StartExam" */));
const PracticalStudentList = lazy(() => import("../components/Exam/Teacher/PracticalStudentList" /* webpackChunkName: "StudentSummary" */));
const ProgressingDetails = lazy(() => import("../pages/ProgressingDetails" /* webpackChunkName: "ProgressingDetails" */));
const ProfileEdits = lazy(() => import("../pages/ProfileEdits" /* webpackChunkName: "ProfileEdits" */));
const AcademicOverview = lazy(() => import("../pages/AcademicOverview" /* webpackChunkName: "AcademicOverview" */));
const ProgressReports = lazy(() => import("../pages/ProgressReports" /* webpackChunkName: "ProgressReports" */));
const PortfolioCertificates = lazy(() => import("../pages/PortfolioCertificates" /* webpackChunkName: "PortfolioCertificates" */));
const DemoExam = lazy(() => import("../components/Exam/Teacher/DemoExam" /* webpackChunkName: "StudentList" */));
const SongFeedback = lazy(() => import("../pages/SongFeedback" /* webpackChunkName: "SongFeedback" */));

const TeacherAcademicOverview = lazy(() => import("../pages/TeacherAcademicOverview" /* webpackChunkName: "AcademicOverview" */));
const TeacherProgressReports = lazy(() => import("../pages/TeacherProgressReports" /* webpackChunkName: "ProgressReports" */));
const TeacherPortfolioCertificates = lazy(() => import("../pages/TeacherPortfolioCertificates" /* webpackChunkName: "PortfolioCertificates" */));
const TeacherMonthlyProgress = lazy(() => import("../pages/TeacherMonthlyProgress" /* webpackChunkName: "PortfolioCertificates" */));

const ClusterAcademicOverview = lazy(() => import("../pages/ClusterAcademicOverview" /* webpackChunkName: "AcademicOverview" */));
const ClusterProgressReports = lazy(() => import("../pages/ClusterProgressReports" /* webpackChunkName: "ProgressReports" */));
const ClusterPortfolioCertificates = lazy(() => import("../pages/ClusterPortfolioCertificates" /* webpackChunkName: "PortfolioCertificates" */));
 
const PrincipalMonthlyReport = lazy(() => import("../pages/PrincipalMonthlyReport" /* webpackChunkName: "PortfolioCertificates" */));
const PrinicipalProgressReport = lazy(() => import("../pages/PrinicipalProgressReport" /* webpackChunkName: "PortfolioCertificates" */));
const ExtraRepertoire = lazy(() => import("../pages/ExtraRepertoire" /* webpackChunkName: "ExtraRepertoire" */));
const EventSongs = lazy(() => import("../pages/EventSongs" /* webpackChunkName: "EventSongs" */));
const MyCourses = lazy(() => import('../components/Teacher/MyCoursesPage' /* webpackChunkName: "Song" */));

export default function Routes() {
  return (
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/" exact component={props => <Login {...props} />} />
            <Route path="/student-login" component={props => <MobilePage {...props} />} />
            <Route path="/verify" component={props => <OtpVerify {...props}  />} />
            <Route path="/form" component={props => <SignupForm {...props} />} /> 
            <Route path="/register" component={props => <NewSignup {...props} />} />
            <Route path="/home" component={props => <Home {...props} />} />
            <Route path="/progress" component={props => <Progress {...props} />} />
            <Route path="/add-another-child" component={props => <NewUserForm {...props} />} />
            <Route path="/song" component={props => <Song {...props} />} />
            <Route path="/courses" component={props => <Courses {...props} />} />
            <Route path="/concepts" component={props => <TeachingTips {...props} />} />
            <Route path="/student-courses" component={props => <StudentCourse {...props} />} />
            <Route path="/objective-details/:id" component={props => <ObjectiveDetails {...props} />} />
            {/* <Route path="/schedule" component={props => <Schedule {...props} />} /> */}
            <Route path="/course-history" component={props => <StudentCourseHistory {...props} /> } />
            <Route path="/import-data" component={props => <ImportData {...props} />} />
            <Route path="/recitalData/:id" component={props => <Lesson {...props} />} />
            <Route path="/objective/:id" component={(props) => <NewObjectiveVideo {...props} />} />
            <Route path="/teacher-List" component={props => <TeacherList {...props} />} />
            <Route path="/cluster-List" component={props => <ClusterList {...props} />} />
            <Route path="/assessments" component={props => <Assessments {...props} />} />
            <Route path="/school-events" component={props => <SchoolEvent {...props} />} />
            <Route path="/assessments-old" component={props => <AssessmentsOld {...props} />} />
            <Route path="/exercises" component={props => <Exercises {...props} />} />
            <Route path="/video-piece" component={props => <VideoPiece {...props} />} />
            <Route path="/student-attendance" component={props => <StudentAttendance {...props} />} />
            <Route path="/Assignment" component={props => <Assignment {...props} />} />
            <Route path="/flip-book" component={props => <FlipBooks {...props} />} />
            <Route path="/music-theroy" component={props => <MusicTheroy {...props} />} />
            <Route path="/student-summary" component={props => <StudentDetails {...props} />} />
            <Route path="/class-room" component={props => <ClassRoom {...props} />} />
            <Route path="/sign-up" component={props => <Signup {...props} />} />
            <Route path="/forget-password" component={props => <ForgetPassword {...props} />} />
            <Route path="/reset-password" component={props => <ConfirmPassword {...props} />} />
            <Route path="/cluster-exam" component={props => <ClusterExam {...props} />} />
            <Route path="/teacher-exam" component={props => <TeacherExam {...props} />} />
            <Route path="/student-exam" component={props => <StudentExam {...props} />} />
            <Route path="/start-exam" component={props => <StartExam {...props} />} />
            <Route path="/student-list" component={(props) => <StudentView {...props} />} />
            <Route path="/cluster-exam-list" component={(props) => <ExamList {...props} />} />
            <Route path="/schedule" component={(props) => <NewSchedule {...props} />} />
            <Route path="/upgrade-students" component={props => <StudentSummary {...props} />} />
            <Route path="/drums-instrument" component={(props) => <DrumsInstrument {...props} />} />
            <Route path="/speechdrama-instrument" component={(props) => <SpeechdramaInstrument {...props} />} />
            <Route path="/hindustani-vocals-instrument" component={(props) => <HindustaniVocalsInstruments {...props} />} />
            <Route path="/drums-flip-book" component={(props) => <DrumsFlipBook {...props} />} />
            <Route path="/speechdrama-flip-book" component={(props) => <SpeechdramaFlipBook {...props} />} />
            <Route path="/hindustani-vocals-flip-book" component={(props) => <HindustaniVocalFlipBook {...props} />} />
            <Route path="/student-practical-exam" component={(props) => <PracticalExamList {...props} />} />
            <Route path="/practical-student-list" component={(props) => <PracticalStudentList {...props} />} />
            <Route path="/portfolio-certificates" component={(props) => <PortfolioCertificates {...props} />} />
            <Route path="/profile" component={(props) => <ProgressingDetails {...props} />} />
            <Route path="/editprofile" component={(props) => <ProfileEdits {...props} />} />
            
            <Route path="/academic-overview" component={(props) => <AcademicOverview {...props} />} />
            <Route path="/reports" component={(props) => <ProgressReports {...props} />} />
            <Route path="/demo-exam" component={(props) => <DemoExam {...props} />} />
            <Route path="/song-feedback" component={(props) => <SongFeedback {...props} />} />
            <Route path="/academic-overview-teacher" component={(props) => <TeacherAcademicOverview {...props} />} />
            <Route path="/reports-teacher" component={(props) => <TeacherProgressReports {...props} />} />
            <Route path="/portfolio-certificates-teacher" component={(props) => <TeacherPortfolioCertificates {...props} />} />
            <Route path="/monthly-progress-teacher" component={(props) => <TeacherMonthlyProgress {...props} />} />

            <Route path="/academic-overview-cluster" component={(props) => <ClusterAcademicOverview {...props} />} />
            <Route path="/reports-cluster" component={(props) => <ClusterProgressReports {...props} />} />
            <Route path="/portfolio-certificates-cluster" component={(props) => <ClusterPortfolioCertificates {...props} />} />
         
            <Route path="/principal-progress-report" component={(props) => <PrinicipalProgressReport {...props} />} />
            <Route path="/principal-monthly-report" component={(props) => <PrincipalMonthlyReport {...props} />} />
            <Route path="/extra-repertoire" component={(props) => <ExtraRepertoire {...props} />} />
            <Route path="/event-songs" component={(props) => <EventSongs {...props} />} />
            <Route path="/my-courses" component={(props) => <MyCourses {...props} />} />


          </Switch>
        </Suspense>
  );
}